
import i18next from 'i18next'
import { messages_en, messages_in, messages_th, messages_vn,messages_cn } from './index'
import { initReactI18next } from 'react-i18next'

const messages = {
  en: messages_en,
  vn: messages_vn,
  th: messages_th,
  in: messages_in,
  cn: messages_cn,
}
const languageId = window.localStorage.getItem("lang") || 'vn'
i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: languageId, // language to use.
  resources: {
    vn: {
      translation: messages.vn
    },
    en: {
      translation: messages.en // 'common' is our custom namespace
    },
    th: {
      translation: messages.th // 'common' is our custom namespace
    },
    in: {
      translation: messages.in // 'common' is our custom namespace
    },
    cn: {
      translation: messages.cn // 'common' is our custom namespace
    }
  },
  debug: process.env.NODE_ENV !== 'production',
  fallbackLng: languageId
})

export default i18next
