// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'
import messagesTh from '@assets/data/locales/th.json'
import messagesIn from '@assets/data/locales/in.json'
import messagesCn from '@assets/data/locales/cn.json'
import messagesVn from '@assets/data/locales/vn.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import userMessagesTh from '@src/assets/data/locales/th.json'
import userMessagesIn from '@src/assets/data/locales/in.json'
import userMessagesCn from '@src/assets/data/locales/cn.json'
import userMessagesVn from '@src/assets/data/locales/vn.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt },
  th: { ...messagesTh, ...userMessagesTh },
  in: { ...messagesIn, ...userMessagesIn },
  cn: { ...messagesCn, ...userMessagesCn },
  vn: { ...messagesVn, ...userMessagesVn }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
const languageId = window.localStorage.getItem("lang")
  const [locale, setLocale] = useState(languageId)
  const [messages, setMessages] = useState(menuMessages[languageId])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
